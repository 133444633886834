<div class="
    d-flex
    flex-column flex-column-fluid
    bgi-position-y-bottom
    position-x-center
    bgi-no-repeat bgi-size-contain bgi-attachment-fixed
  ">
  <!--begin::Content-->
  <div class=" d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
    <a routerLink="/" class="mb-12">
        <!-- <img alt="Logo" src="./assets/media/logos/default-dark2.png" class="h-45px" /> -->
        <img src="assets/imgs/logo.png" class="mb-5 h-45px" alt="SocialConnector logo" />
    </a>

    <!--begin::Content body  -->
    <div  class="w-lg-900px bg-body rounded shadow-sm p-5 p-lg-7 mx-auto">
    <span class="badge badge-light-success mb-5">Last Revised: 13/11/2022</span>
    <h2 class="mb-4">Social Connector Terms and Condition </h2>
     <p>
        Welcome to our Social Connector Terms of Service (“Terms”). These Terms are provided by Social Connector. and our affiliates 
        (referred to as “we”, “us”, “our” and “Social Connector”) and govern your use of app.socialconnector.io and our other websites, webpages, platforms, applications, 
        products and services (collectively, our “Site”). Throughout these Terms, we will refer to you as “you” and “User”.
     </p>
     <p>
        Our Software is provided to you to enable you to support and interact with your community and your favorite content
         creators, influencers, athletes, musicians, artists and celebrities (collectively, “Creators”). For Creators, our
          software enables you to engage with, receive support from, and reward your fans. These Terms constitute a binding,
           legal agreement between you and us and apply
         to all Users who access or use our website. Special terms that apply only to Creators are described below.
     </p>
     <p>
         <strong>
            IMPORTANT NOTICE – LIMITATION OF LIABILITY, CLASS ACTION WAIVER, & ARBITRATION REQUIREMENT: THESE TERMS CONTAIN PROVISIONS THAT 
            LIMIT OUR LIABILITY TO YOU AND REQUIRE YOU TO RESOLVE ANY DISPUTE WITH US THROUGH FINAL AND BINDING ARBITRATION ON AN INDIVIDUAL
             BASIS, WHICH MEANS THAT YOU WAIVE AND WILL NOT HAVE THE RIGHT FOR A JUDGE OR JURY TO DECIDE YOUR CLAIMS, IF ANY, AND THAT YOU MAY
              NOT PROCEED IN A CLASS, CONSOLIDATED OR REPRESENTATIVE CAPACITY.
             SEE “DISCLAIMER OF WARRANTIES & LIMITATIONS OF LIABILITY” AND “DISPUTE RESOLUTION” BELOW FOR MORE INFORMATION.
         </strong>
     </p>
     <p>
        You may have the opportunity on our software to view your digital asset including assets native to Socialconnector like $GARY,
         Creator Coin, the creator-branded social token, and non-fungible tokens (“NFTs” and, collectively with $GARY and Creator Coin,
          “Digital Assets”). Certain portions, features, or functionalities of the software may be subject to different or additional terms,
           rules, guidelines or policies (“Additional Rules”), and we may provide such Additional Rules to you via postings, pop-up notices, links,
            or other means at the time that you access or use the relevant area, feature or functionality. From time to time, such Additional 
        Rules may conflict with this agreement; in the event of such a conflict, the Additional Rules will control.
     </p>
     <h4>1. Changes to our Site</h4>
     <span class="text-muted mb-2"> These Terms may be updated.</span>
     <p>
        We may update our Site often, and the effective date of these Terms will be included at the top of this webpage. You acknowledge and agree 
        that we may change or discontinue any part of our Site, products and services at any time, with or without notice to you.
        Review these terms regularly
        Your continued use of the Site after the Effective Date constitutes your acceptance of any amended Terms. 
        The amended Terms supersede all previous versions of the Terms. For this reason, we encourage you to review these Terms any time you access
         or use the Site and to print out a copy of these Terms for your records.
         If you have any questions about these Terms, please send an email to team@digitalmarketing.org
     </p>
     <h4>2. Account Creation</h4>
     <span class="text-muted mb-2"> Information we need from you </span>
     <p>
        We will need some information from you to get you set up with an account on our Site, including your legal name and your contact information, including your 
        email address and social media and social messaging handles. You may be able to access the Site by logging in with your social media accounts or linking to other 
        social media platforms, in which case such accounts and platforms may pass such information directly to us. You agree that you will provide us with accurate, true,
         and complete information about yourself whenever you are prompted to provide us with information, and that you will update such information whenever it changes. 
         Failure to provide accurate information may prevent you from using certain features across the Site.
        Your responsibilities over your information
        You agree that you will maintain the security of all information that you provide to us, and that it is solely your responsibility to keep your username, passwords,
         devices used to access the Site, and all other materials secure and confidential. We assume that any transaction initiated or activity that takes place through your
          account using your credentials is authorized by you. If you become aware of any unauthorized access to your account or any transaction made through your account that 
          was not authorized by you, it is your responsibility to let us know immediately by contacting us at team@digitalmarketing.org It is important that you regularly check your
           account to ensure that all activity that occurs on it was authorized by you. We are not liable for any damages or losses
         that you incur due to unauthorized access, unless you have notified us immediately as indicated above. You may not sell or otherwise transfer your account or any portion thereof.
     </p>
     <h4>3. Eligibility</h4>
     <span class="text-muted mb-2"> 
        Your access is conditioned upon you meeting eligibility requirements
     </span>
     <p>
        If you access or use our Site, you represent and warrant the following:
        
        <li> (i) you are at least 13 years old, or have signed permission from your parent or legal guardian to access or use our Site, </li> 
        <li> (ii) if you are 13 or older but under the age of majority in your jurisdiction of residence that you have the consent of a parent or guardian to access or use our Site </li> 
        <li>  (iii) you are legally competent and able to be bound by these Terms, and </li> 
        <li>   (iv) you have not been previously banned from using our Site for any reason. </li> 
       
        If you are unable to make these representations and warranties or don’t agree to any part of our Terms, you may not access or use our Site.
     </p>
     <h4>
        4. Digital Assets
     </h4>
     <span class="text-muted mb-2"> 
        What you can do with Digital Assets on this Site
     </span>
     <p>
        As described above, through the Site, you may have the opportunity to view, fund and swap assets, including digital assets native to the 
        $Gary coin Network (a Solana token) called Creator Coin (collectively, “Digital Assets”). Digital Assets are not legal tender and are not 
        backed by any government, and they are not subject to protections or insurance provided by the Federal Deposit Insurance Corporation or the
         Securities Investor Protection Corporation. Digital Assets that you may acquire on our Site are
         not intended as investments - they are designed for use and engagement between Creators and their fans.
     </p>
     <h4>
        5. Creator Specific Terms
     </h4>
     <p>
        The following Section applies solely to Creators on this Site, and not to Users who are not Creators. All other sections of these Terms apply to all
         Users of this Site, including Creators. <br>
        Our Site provides you, as one of our Creators, with tools to engage with, receive support from and reward your fans, including through creation 
        of your own Creator Coin. You will have the opportunity to build your own digital economy and interact with and grow your community. We will need some
         information from you to get you set up and onboarded as a Creator and to maintain or improve your profile. Your profile will enable you to set up your 
         own personalized information on our Site, including the branding of your Creator Coin. We may ask you for your name, your screen name, alias or preferred nickname, 
         your contact information, including your email address and social media and social messaging handles, and your headshot, bio and description of your skills, specializations
          or profession to be used to create and maintain your profile, as well as other information that may be relevant to getting you set up. We may ask you for additional information 
          from time to time to improve your profile, the Site or to help promote the Site. All materials that you provide to us during the Creator onboarding and maintenance process are 
          included in the definition of Creator Materials, defined further below in this Section. Please note that you will not be able to have your profile or Creator Coin featured on our 
          Site until you have provided us with all requested materials. You agree that you will provide us with accurate, true, and complete information about yourself whenever you are prompted
           to provide us with information, and that you will update such information whenever it changes.
         <li> <strong> Your submissions to the Site </strong> </li> 
        You may have the opportunity to, and you may choose to, interact with our Site through submissions of chats, materials and posts to our Site or through third-party social media platforms
         and sites. You may also have comments, suggestions or other feedback about our Site, products and services that you may provide. This content is collectively referred to in these Terms as
          “Submissions”. In making any Submission, you hereby grant to us a non-exclusive, royalty-free, fully paid, unlimited, worldwide, sublicensable, perpetual, and irrevocable license, in any and
           all manner and media, whether now known or hereinafter invented, to use, reproduce, license, distribute, modify, adapt, reformat, publicly perform, publicly display, create derivative works of,
            and otherwise use the Submissions for any and all commercial, promotional, marketing, promotional and development purposes. You waive all moral rights that you may have in your Submissions.
             You understand that we have no obligation to use, publicize, distribute or take any actions with respect to your Submission, and that if we do use your Submission, that we do so in our sole 
             discretion and may remove, or refuse to remove, any Submission at our sole discretion. Any Submission that you provide to us is at your own risk and in full acknowledgement of these Terms. 
             In granting us this license, you represent and warrant that you possess all rights necessary to grant us the license rights described above.
     </p>
     <h4>
        6. Third Party Websites and Services
     </h4>
     <span class="text-muted mb-2"> 
        Third party services on the Site
     </span>
     <p>
        Certain third party services may be required for you to transact on our Site and to use our products and services. These third party service providers may include: third party payment processors
         and payment services providers, compliance service providers, custodial service providers, wallet operators, authentication service providers, application developers and others. You represent that
          you are legally competent and able to agree to the terms and conditions of such third party service providers as such terms and conditions may be updated and amended from time to time. 
        Third party linked sites
        The Site may also contain links to third-party websites and services, including social media and ecommerce sites (collectively, “Linked Sites”). Linked Sites are not under our control, and we take no
         responsibility for any information or materials on, or any transactions, communications or transmissions conducted on or received from, any Linked Site. By including a link on our Site to the Linked Site,
          we are not endorsing the Linked Site. We provide links to Linked Sites for your convenience only, and your access Linked Sites is done at your own risk.
     </p>
     <h4>
        7. Privacy Policy
     </h4>
     <p>
        At Social Connector, accessible from <a href="https://app.socialconnector.io/">https://app.socialconnector.io/</a>, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected
         and recorded by Social Connector and how we use it.
        If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
        This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Social Connector. This policy is not 
        applicable to any information collected offline or via channels other than this website. Our Privacy Policy was created with the help of the Free Privacy Policy Generator.
     </p>
     <h5>Consent</h5>
     <span class="text-muted">
        By using our website, you hereby consent to our Privacy Policy and agree to its terms.
     </span>
     <h5>Information we collect</h5>
     <p>
        The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
        If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other
         information you may choose to provide.
        When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
     </p>
     <h5>How we use your information</h5>
     <span class="text-muted">We use the information we collect in various ways, including to:</span>
     <li>Provide, operate, and maintain our website</li>
     <li>Improve, personalize, and expand our website</li>
     <li>Understand and analyze how you use our website</li>
     <li>Develop new products, services, features, and functionality</li>
     <li>Communicate with you, either directly or through one of our partners, including for customer service,
          to provide you with updates and other information relating to the website,
          and for marketing and promotional purposes</li>
     <li>Send you emails</li>
     <li>Find and prevent fraud</li>
     <h5>Log Files</h5>
     <p>
        Social Connector follows a standard procedure of using log files. These files log visitors when they visit
         websites. All hosting companies do this and a part of hosting services' analytics. The information collected
          by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and 
          time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information
           that is personally identifiable. The purpose of the information is for analyzing trends,
         administering the site, tracking users' movement on the website, and gathering demographic information.
     </p>
     <h5>
        Cookies and Web Beacons
     </h5>
     <p>
        including visitors' preferences, and the pages on the website that the visitor accessed or visited. The
         information is used to optimize the users' experience by customizing our web page content based on visitors'
          browser type and/or other information.
        For more general information on cookies, please read "What Are Cookies" from Cookie Consent.
     </p>
     <h5>
        Advertising Partners Privacy Policies
     </h5>
     <p>
        You may consult this list to find the Privacy Policy for each of the advertising partners of Social Connector.
        Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Social Connector, which are sent directly to users'
         browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
        Note that Social Connector has no access to or control over these cookies that are used by third-party advertisers.        
     </p>
     <h5>
        Third Party Privacy Policies
     </h5>
     <p>
        Social Connector's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult
         the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices 
         and instructions about how to opt-out of certain options.
        You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management 
        with specific web browsers, it can be found at the browsers' respective websites
     </p>
     <h5>
        CCPA Privacy Rights (Do Not Sell My Personal Information)
     </h5>
     <p>
        Under the CCPA, among other rights, California consumers have the right to:
        Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.
        Request that a business delete any personal data about the consumer that a business has collected.
        Request that a business that sells a consumer's personal data, not sell the consumer's personal data.
        If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.        
     </p>  
     <h5>
        GDPR Data Protection Rights
     </h5>
     <p>
        We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
        The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.
        The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.
        The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
        The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
        The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
        The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
        If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.
        
     </p>  
     <h5>
        Children's Information
     </h5>
     <p>
        Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe,
         participate in, and/or monitor and guide their online activity.
        Social Connector does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that
         your child provided this kind of information on our website, 
        we strongly encourage you to contact us immediately
        and we will do our best efforts to promptly remove such information from our records.        
     </p>  
     <h4>8. Disclaimers Of Warranties & Limitations Of Liability</h4>
     <p>
         <strong>
            UNLESS EXPRESSLY PROVIDED OTHERWISE, OUR SITE AND ANY ASSOCIATED PRODUCTS AND SERVICES 
            ARE PROVIDED "AS IS" AND “AS AVAILABLE” 
            WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. 
         </strong>
         <span>
            We specifically disclaim all warranties and conditions of any kind, including all implied 
            warranties and conditions of merchantability, fitness for a particular purpose, title, non-infringement,
             freedom from defects,
             uninterrupted use and all warranties implied from any course of dealing or usage of trade
         </span>
         <li>
            (a) the Site will meet your requirements,
         </li>
         <li>
            (b) operation of the Site will be uninterrupted or be virus- or error-free or
         </li>
         <li>
            (c) errors will be corrected.
         </li>
         Any oral or written advice provided by us or our authorized agents does not and will not create any warranty.
         <li>
             <strong>
                SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES WHICH MEANS THAT SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
                YOU AGREE THAT IN NO EVENT WILL WE OR AND OUR AFFILIATES AND OUR RESPECTIVE DIRECTORS, OFFICERS, AGENTS, EMPLOYEES, PRINCIPALS, SUCCESSORS, ASSIGNS, SUBSIDIARIES
                 OR AFFILIATES (COLLECTIVELY, THE “SOCIAL CO PARTIES”) BE LIABLE (Y) FOR DAMAGES OF ANY KIND, INCLUDING DIRECT, INDIRECT, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL
                  OR PUNITIVE DAMAGES (INCLUDING, BUT NOT
                 LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOSS OF USE, DATA OR PROFITS OR BUSINESS INTERRUPTION), 
                 HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, WHETHER ARISING IN ANY WAY IN CONNECTION WITH THESE TERMS AND WHETHER IN CONTRACT, STRICT LIABILITY OR TORT (INCLUDING
                  NEGLIGENCE OR OTHERWISE), EVEN IF THE SOCIAL CONNECTOR PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE OR (Z) FOR ANY OTHER CLAIM, DEMAND OR DAMAGES WHATSOEVER 
                  RESULTING FROM OR ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF OUR SITE, PRODUCTS AND SERVICES.                
             </strong>
         </li>
        <span>
            The disclaimer of liability detailed above will not apply to the extent prohibited by applicable law in the jurisdiction 
            of your place of residence. You acknowledge and agree that the above limitations of liability together with the other provisions
             in these Terms that limit liability are essential terms and that we would not be willing to grant you the rights set forth in these
              Terms but for your agreement to the above limitations of liability.
        </span>
     </p>
     <h4>9. Indemnification</h4>
     <p>
        You agree to indemnify and defend the social connector Parties from and against all losses, liabilities, actual or pending claims, 
        actions, damages, expenses, costs of defense and reasonable attorneys’ fees brought against us by any third-party arising from your
         violation of these Terms, the rights of a third-party or applicable law. We reserve the right, at our own expense, to assume the 
         exclusive defense and control of any matter subject to indemnification hereunder. 
        In any event, no settlement that affects our rights or obligations may be made without our prior written approval.
     </p>  
     <h4>
        10. Dispute Resolution
     </h4>
     <p>
        YOU AND WE AGREE THAT ALL CLAIMS WILL BE RESOLVED BY BINDING ARBITRATION IN THE MANNER SPECIFIED IN THIS SECTION AND THAT YOU AND WE WAIVE ANY RIGHT TO BRING SUCH CLAIMS BEFORE ANY COURT OF LAW.
        YOU AND WE FURTHER AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING.
         OTHER RIGHTS THAT YOU WOULD HAVE IF YOU WENT TO COURT, SUCH AS ACCESS TO DISCOVERY, ALSO MAY BE UNAVAILABLE OR LIMITED IN ARBITRATION.  ALL ARBITRATION WILL TAKE PLACE IN SAN JUAN, PUERTO RICO.        
     </p>  
     <h4>11. Termination</h4>
     <span class="text-muted">Termination of access and account</span>
     <p>
        These Terms automatically terminate, and your rights to use our Site, products and services also terminate,
         if you fail to comply with any aspect of our Terms. In addition, we may terminate, suspend or modify your 
         account or your access to the Site, with or without notice to you, at any time for any reason. If you wish to 
         terminate your participation on our Site, please provide us with written notice of your desire to terminate via
        email to <a href="mailto:team@digitalmarketing.org"> team@digitalmarketing.org </a>
        <li><strong> Reservation of our rights</strong></li>
        Termination of these Terms or your account will not limit any of our other rights or remedies. Those provisions that by
         their nature are intended to survive termination or expiration of these Terms shall so survive.
         <li><strong> English Language Controls</strong></li>
         These Terms and all related documentation will be drafted in English. While certain text in these Terms may be made available 
         in languages other than
         English (whether translated by a person or solely by computer software), the English language version controls.
     </p>
    </div>
    <!--end::Content body-->
  </div>
  <!--end::Content-->

  <!-- begin::Footer -->
  <div class="d-flex flex-center flex-column-auto p-10">
    <div class="d-flex align-items-center fw-bold fs-6">
      <a class="text-muted text-hover-primary px-2 cursor-pointer">How To Use Social Connector</a>
      <a class="text-muted text-hover-primary px-2 cursor-pointer">Buy $GARY</a>
      <a href="/terms" class="text-muted text-hover-primary px-2 cursor-pointer">Terms & Condition</a>
    </div>
  </div>
  <!-- end::Footer -->
</div>
