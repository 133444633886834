import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './modules/auth/services/auth.guard';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { PayComponent } from './pages/checkout/pay/pay.component';
import { InlineCheckoutComponent } from './pages/inline-checkout/inline-checkout.component';
import { InlinePayComponent } from './pages/inline-pay/inline-pay.component';
import { TermsconditionComponent } from './pages/termscondition/termscondition.component';
import { TransactionsDetailsComponent } from './pages/transactions-details/transactions-details.component';
import { ViewComponent } from './pages/view/view.component';
import { MigrationCheckerComponent } from './pages/migration-checker/migration-checker.component';
import { TrackPowComponent } from './pages/track-pow/track-pow.component';
import { MintComponent } from './pages/mint/mint.component';
import { FeesComponent } from './pages/fees/fees.component';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./modules/errors/errors.module').then((m) => m.ErrorsModule),
  },
  {
    path: 'checkout/:token',
    component: CheckoutComponent,
  },
  {
    path: 'checkout/success/:token',
    component: CheckoutComponent,
  },
  {
    path: 'checkout/failed/:token',
    component: CheckoutComponent,
  },
  {
    path: 'checkout/pay/:ref',
    component: PayComponent,
  },
  {
    path: 'inline/:ref',
    component: InlineCheckoutComponent,
  },
  {
    path: 'pay/:ref',
    component: InlinePayComponent,
  },
  {
    path: 'transactions/:id',
    component: TransactionsDetailsComponent,
  },
  // {
  //   path: 'pow/:id',
  //   component: ViewComponent,
  // },
  {
    path: 'pow/:id',
    component: TrackPowComponent
  },
  {
    path: 'terms',
    component: TermsconditionComponent,
  },
  {
    path: 'migration_checker',
    component: MigrationCheckerComponent,
  },
  {
    path: 'fees',
    component: FeesComponent
  },
  {
    path: 'mint/:hash',
    component: MintComponent,
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./_metronic/layout/layout.module').then((m) => m.LayoutModule),
  },

  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
