import { CanActivate } from '@angular/router';
import { Routes } from '@angular/router';
import { AuthGuard } from '../modules/auth/services/auth.guard';
import { IsEmailVerifiedGuard } from '../modules/auth/services/email-verified';
import { CreatorGuard } from '../modules/creator/creator.guard';
import { FeesComponent } from './fees/fees.component';

const Routing: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGuard, IsEmailVerifiedGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  // {
  //   path: 'dashboard',
  //   canActivate: [AuthGuard, IsEmailVerifiedGuard],
  //   loadChildren: () => import('./dashboard-v2/dashboard-v2.module').then((m) => m.DashboardV2Module),
  // },
  // {
  //   path: 'sc/teams',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('../modules/team/team.module').then((m) => m.TeamModule),
  // },
  {
    path: 'sc/teams',
    loadChildren: () =>
      import('../modules/team/team.module').then((m) => m.TeamModule),
  },
  {
    path: 'sc/nftloader',
    loadChildren: () =>
      import('../modules/nft-loader/nft-loader.module').then((m) => m.NftLoaderModule),
  },
  {
    path: 'sc/manage-role',
    loadChildren: () =>
      import('../modules/manage-role/manage-role.module').then((m) => m.ManageRoleModule),
  },
  // {
  //   path: 'builder',
  //   canActivate: [AuthGuard, IsEmailVerifiedGuard],
  //   loadChildren: () =>
  //     import('./builder/builder.module').then((m) => m.BuilderModule),
  // },
  {
    path: 'sc/clubhouse',
    loadChildren: () =>
      import('../modules/clubhouse/clubhouse.module').then(
        (m) => m.ClubhouseModule
      ),
  },
  {
    path: 'sc/searchie',
    loadChildren: () =>
      import('../modules/searchie/searchie.module').then(
        (m) => m.SearchieModule
      ),
  },
  {
    path: 'sc/instagram',
    loadChildren: () =>
      import('../modules/instagram/instagram.module').then(
        (m) => m.InstagramModule
      ),
  },
  {
    path: 'sc/socialpay',
    loadChildren: () =>
      import('../modules/social-pay/social-pay.module').then(
        (m) => m.SocialPayModule
      ),
  },
  {
    path: 'sc/obvio',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/obvio/obvio.module').then((m) => m.ObvioModule),
  },
  {
    path: 'sc/account',
    canActivate: [AuthGuard, IsEmailVerifiedGuard],
    loadChildren: () =>
      import('../modules/account/account.module').then((m) => m.AccountModule),
  },
  {
    path: 'sc/settings',
    redirectTo: '/sc/account/connected_accounts',
    pathMatch: 'full',
  },

  {
    path: 'sc/creator',
    canActivate: [AuthGuard, CreatorGuard],
    loadChildren: () =>
      import('../modules/creator/creator.module').then((m) => m.CreatorModule),
  },
  {
    path: 'sc/twitter',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/twitter/twitter.module').then((m) => m.TwitterModule),
  },
  {
    path: 'sc/bots',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/bots/bots.module').then((m) => m.BotsModule),
  },
  {
    path: 'sc/web3_form',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/web3/web3.module').then((m) => m.Web3Module),
  },
  {
    path: 'sc/segments',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/segments/segments.module').then((m) => m.SegmentsModule),
  },

  // {
  //   path: 'sc/template_generator',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('../modules/template/template.module').then((m) => m.TemplateModule),
  // },
  {
    path: 'sc/pow',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/pow/pow.module').then((m) => m.POWModule),
  },

  {
    path: 'sc/migration',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/migration/migration.module').then((m) => m.MigrationModule),
  },

  {
    path: 'sc/digital_collectibles',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/digital_collectibles/nft.module').then((m) => m.NftModule),
  },
  {
    path: 'sc/event_calendar',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/event-calendar/event-calendar.module').then((m) => m.EventCalendarModule),
  },
  {
    path: 'sc/landing_page',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../modules/landing-page/landing-page.module').then((m) => m.LandingPageModule),
  },

  // {
  //   path: 'sc/pages/wizards',
  //   loadChildren: () =>
  //     import('../modules/wizards/wizards.module').then((m) => m.WizardsModule),
  // },
  // {
  //   path: 'sc/widgets',
  //   loadChildren: () =>
  //     import('../modules/widgets-examples/widgets-examples.module').then(
  //       (m) => m.WidgetsExamplesModule
  //     ),
  // },
  // {
  //   path: 'apps/chat',
  //   loadChildren: () =>
  //     import('../modules/apps/chat/chat.module').then((m) => m.ChatModule),
  // },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'error/404',
  },
];

export { Routing };
