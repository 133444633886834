import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  SettingsService,
  UserType,
} from 'src/app/modules/account/settings.service';
import { PermissionService } from 'src/app/modules/auth/services/permission.service';
import { TokenService } from 'src/app/modules/auth/services/token.service';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.scss'],
})
export class AsideMenuComponent implements OnInit {
  appAngularVersion: string = environment.appVersion;
  appPreviewChangelogUrl: string = environment.appPreviewChangelogUrl;
  id: any;
  coin: any;
  showData: boolean;
  user$: Observable<UserType>;
  manageTeam: boolean;
  team: any;
  user_id: any;
  current_team_id: any;
  showSettings: boolean;
  viewDrop: boolean;
  viewTweet: boolean;
  showCSeettings: boolean;
  showCSupporter: boolean;
  showCTrades: boolean;
  showCRewards: boolean;
  track: boolean;
  showCHB: boolean;
  showdsb: boolean;
  showSeactchieConfig: boolean;
  showSeactchieRole: boolean;
  showEvents: boolean;
  manageEvents: boolean;
  viewEventsLogs: boolean;
  manageEventsAccess: boolean;
  manageEventsOrg: boolean;
  showEventsPerm: boolean;
  showClubs: boolean;
  showSuperfan: boolean;
  showRoomHistory: boolean;
  showweeklyReport: boolean;
  showcomparism: boolean;
  showProfile: boolean;
  showForm: boolean;
  showResponse: boolean;
  socialPayAccessGranted: boolean;
  trackRoom: boolean;
  mtrackRoom: boolean;
  twitter_username: any;
  managerole: boolean;
  viewSegment: boolean;
  manageSegment: boolean;
  instagram_username: any;
  viewInstagram: boolean;
  manageInstagram: boolean;
  manageBadge: boolean;
  migrate_nft: boolean;
  manage_pow: boolean;
  manage_segment_airdrop: boolean;
  manage_collectible: boolean;
  manage_event: boolean;
  recurring_payment: boolean;
  constructor(
    private token: TokenService,
    private settings: SettingsService,
    private permission: PermissionService
  ) {
    this.permission.getUserByToken().subscribe({
      next: (res) => {
        const a = this.token.getUser();

        this.id = a?.ch_user_id;
        this.twitter_username = a.twitter_username
        // const insta_info = a?.instagram_user_info;
        // if(insta_info !== null) {
        //   this.instagram_username = JSON.parse(insta_info)['username'];
        // }
        this.user_id = a?.id;
        this.current_team_id = a?.current_team_id;
        if (
          this.current_team_id !== null &&
          this.current_team_id != this.user_id
        ) {
          this.showSettings = false;
        } else if (
          this.current_team_id === null ||
          this.current_team_id == this.user_id
        ) {
          this.showSettings = true;
        } else {
          this.showSettings = true;
        }
        this.coin = a?.permission_coin;
        this.checkManage(a?.permissions);
        // //console.log(a);
        this.showData = !(this.coin === null || this.coin === undefined);
      },
    });
  }

  checkManage(a: any) {
    // check access to social pay module section start
    const socialPayAccess = a.find((obj: any) => {
      return obj.name === 'view_social_pay';
    });
    this.socialPayAccessGranted = !!socialPayAccess;
    // check access to social pay module section ends
    const first = a.find((obj: any) => {
      return obj.name === 'manage_team';
    });
    this.manageTeam = !!first;

    const second = a.find((obj: any) => {
      return obj.name === 'view_twitter_drops';
    });
    this.viewDrop = !!second;

    const second2 = a.find((obj: any) => {
      return obj.name === 'track_space';
    });
    this.track = !!second2;

    const third = a.find((obj: any) => {
      return obj.name === 'view_monitored_tweets';
    });
    this.viewTweet = !!third;

    const fourth = a.find((obj: any) => {
      return obj.name === 'list_coin_data_for_creators';
    });
    this.showCSeettings = !!fourth;

    const fifth = a.find((obj: any) => {
      return obj.name === 'view_creator_supporters';
    });
    this.showCSupporter = !!fifth;

    const sixth = a.find((obj: any) => {
      return obj.name === 'view_creator_trades';
    });
    this.showCTrades = !!sixth;

    const secenth = a.find((obj: any) => {
      return obj.name === 'view_creator_rewards';
    });
    this.showCRewards = !!secenth;

    const chb = a.find((obj: any) => {
      return obj.name === 'view_clubhouse_bots';
    });
    this.showCHB = !!chb;

    const dsb = a.find((obj: any) => {
      return obj.name === 'view_discord_bots';
    });
    this.showdsb = !!dsb;

    const searchieconf = a.find((obj: any) => {
      return obj.name === 'view_searchie_config';
    });
    this.showSeactchieConfig = !!searchieconf;

    const searchierole = a.find((obj: any) => {
      return obj.name === 'view_searchie_info';
    });
    this.showSeactchieRole = !!searchierole;

    const events = a.find((obj: any) => {
      return obj.name === 'view_events';
    });
    this.showEvents = !!events;

    const eventsManage = a.find((obj: any) => {
      return obj.name === 'view_event_settings';
    });
    this.manageEventsOrg = !!eventsManage;

    const eventsLog = a.find((obj: any) => {
      return obj.name === 'manage_event_access';
    });
    this.showEventsPerm = !!eventsLog;

    const clubs = a.find((obj: any) => {
      return obj.name === 'view_clubs_clubhouse';
    });
    this.showClubs = !!clubs;

    const superF = a.find((obj: any) => {
      return obj.name === 'view_super_fans_clubhouse';
    });
    this.showSuperfan = !!superF;

    const roomHis = a.find((obj: any) => {
      return obj.name === 'view_room_stats_clubhouse';
    });
    this.showRoomHistory = !!roomHis;

    const weeklyReport = a.find((obj: any) => {
      return obj.name === 'view_weekly_reports_clubhouse';
    });
    this.showweeklyReport = !!weeklyReport;

    const comparism = a.find((obj: any) => {
      return obj.name === 'view_comparison_clubhouse';
    });
    this.showcomparism = !!comparism;

    const profile = a.find((obj: any) => {
      return obj.name === 'view_clubhouse_profile_clubhouse';
    });
    this.showProfile = !!profile;

    const viewForm = a.find((obj: any) => {
      return obj.name === 'view_form_config';
    });
    this.showForm = !!viewForm;

    const viewResponse = a.find((obj: any) => {
      return obj.name === 'view_form_response';
    });
    this.showResponse = !!viewResponse;

    const viewtrackRoom = a.find((obj: any) => {
      return obj.name === 'view_track_room_clubhouse';
    });
    this.trackRoom = !!viewtrackRoom;

    const managetrackRoom = a.find((obj: any) => {
      return obj.name === 'manage_track_room_clubhouse';
    });
    this.mtrackRoom = !!managetrackRoom;
    const managerole = a.find((obj: any) => {
      return obj.name === 'manage_role';
    });
    this.managerole = !!managerole


    const viewSegment = a.find((obj: any) => {
      return obj.name === 'view_segment';
    });
    this.viewSegment = !!viewSegment

    const manageSegment = a.find((obj: any) => {
      return obj.name === 'manage_segment';
    });
    this.manageSegment = !!manageSegment

    const viewInstagram = a.find((obj: any) => {
      return obj.name === 'view_instagram';
    });
    this.viewInstagram = !!viewInstagram

    const manageInstagram = a.find((obj: any) => {
      return obj.name === 'manage_instagram';
    });
    this.manageInstagram = !!manageInstagram

    const manageBadge = a.find((obj: any) => {
      return obj.name === 'manage_badge';
    });
    this.manageBadge = !!manageBadge

    const migrate_nft = a.find((obj: any) => {
      return obj.name === 'migrate_nft';
    });
    this.migrate_nft = !!migrate_nft

    const manage_pow = a.find((obj: any) => {
      return obj.name === 'manage_pow';
    });
    this.manage_pow = !!manage_pow


    const manage_segment_airdrop = a.find((obj: any) => {
      return obj.name === 'manage_segment_airdrop';
    });
    this.manage_segment_airdrop = !!manage_segment_airdrop


    const manage_collectible = a.find((obj: any) => {
      return obj.name === 'manage_collectible';
    });
    this.manage_collectible = !!manage_collectible

    const manage_event = a.find((obj: any) => {
      return obj.name === 'manage_event';
    });
    this.manage_event = !!manage_event
    const recurring_payment = a.find((obj: any) => {
      return obj.name === 'recurring_payment';
    });
    this.recurring_payment = !!recurring_payment

  }
  test() { }


  // manage_segment_airdrop

  // getTeams() {

  //   // team_id: 653
  //   // team_name: "jonathan solagbade
  //   this.settings.getMyTeam().subscribe({
  //     next: (res: any) => {
  //       this.team = res.data;
  //       // //console.log(res);
  //     },
  //   });
  // }

  ngOnInit(): void {
    // this.user$ = this.settings.currentUserSubject.asObservable();
  }
}
