import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const apiUrl = `${environment.apiUrl}`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root'
})
export class CollectiblesService {

  constructor(
    private http: HttpClient,
  ) { }

  getSolanaCoins() {

    return this.http.get(`${apiUrl}/solana/coin/role`, httpOptions).pipe();
  }

  storeCollection(data) {
    return this.http.post(`${apiUrl}/solana/nfts/collection/store`, data, httpOptions);
  }

  listCollections() {
    return this.http.get(`${apiUrl}/solana/nfts/collection`, httpOptions);
  }
  listSingleCollections(address) {
    return this.http.get(`${apiUrl}/solana/nfts/collection/details/${address}`, httpOptions);
  }

  updateCollection(id, data) {
    return this.http.patch(`${apiUrl}/solana/nfts/collection/update/${id}`, data, httpOptions);
  }

  createCollectibles(data) {
    return this.http.post(`${apiUrl}/solana/nfts/store`, data, httpOptions);
  }


  listAllCollectibles() {
    return this.http.get(`${apiUrl}/solana/nfts`, httpOptions);
  }

  createMintPages(data) {
    return this.http.post(`${apiUrl}/solana/nfts/mint-page/store`, data, httpOptions);
  }

  allCreatorMintPage() {
    return this.http.get(`${apiUrl}/solana/nfts/mint-page`, httpOptions);
  }

  mintPagesWithHash(hash) {
    return this.http.get(`${apiUrl}/solana/nfts/mint-page/nft/${hash}`, httpOptions);
  }
  getNFTMintPagewithMintHash(hash) {
    return this.http.get(`${apiUrl}/solana/nfts/mint-page/details/${hash}`, httpOptions);
  }

  getNFTMintPagewithMintHashExternal(hash) {
    return this.http.get(`${apiUrl}/solana/nfts/mint-page-external/details/${hash}`, httpOptions);
  }


  updateStatus(data) {
    return this.http.post(`${apiUrl}/solana/nfts/mint-page/update-status`, data, httpOptions);
  }

  startSale(data) {
    return this.http.post(`${apiUrl}/solana/nfts/mint-page/start-sale`, data, httpOptions);
  }

  mintNft(data) {
    return this.http.post(`${apiUrl}/solana/nfts/mint-nft`, data, httpOptions);
  }

  mintNftBackground(data) {
    return this.http.post(`${apiUrl}/solana/nfts/mint-nft-background`, data, httpOptions);
  }

  mintStatus(reference) {
    return this.http.get(`${apiUrl}/solana/nfts/get-mint-status/${reference}`, httpOptions);
  }
  mintNftByCreator(data) {
    return this.http.post(`${apiUrl}/solana/nfts/mint-nft-by-creator`, data, httpOptions);
  }

  mintToSegment(data) {
    return this.http.post(`${apiUrl}/solana/nfts/mint-nft-segment`, data, httpOptions);
  }
  viewMintToSegment() {
    return this.http.get(`${apiUrl}/solana/nfts/mint-nft-segments`, httpOptions);
  }

  viewUserMintSegment(data: any) {
    return this.http.get(`${apiUrl}/solana/nfts/get-user-mint-nft-segments/${data}`, httpOptions);
  }

  viewUsersMint(hash) {
    return this.http.get(`${apiUrl}/solana/nfts/nft-mint-page-users/${hash}`, httpOptions);
  }

  retryFailedMint(mintUid) {
    return this.http.get(`${apiUrl}/solana/nfts/retry-failed-nft-segments/${mintUid}`, httpOptions)
  }

  getSegmentMintCount(id) {
    return this.http.get(`${apiUrl}/solana/nfts/get-segment-user-count/${id}`, httpOptions)
  }
}


